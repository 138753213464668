.widget
  cursor: pointer
  border: 1px solid rgba(255,255,255,0.25)
  border-radius: 0.5em
  .widget-bg
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    border-radius: 0.5em
  .widget-centered
    position: absolute
    padding: 1em
    top: 50%
    left: 0
    right: 0
    transform: translateY(-50%)
  .widget-bottom
    position: absolute
    padding: 1em
    bottom: 0
    left: 0
    right: 0
  .widget-top
    position: absolute
    padding: 1em
    left: 0
    right: 0
    top: 0
  .widget-right
    text-align: right
  .widget-left
    text-align: left
  .widget-center
    text-align: center
  .widget-icon
    display: inline-block
    width: 100%
    height: 2em
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    &.top
      position: absolute
      top: 0.25em
    &.bottom
      position: absolute
      bottom: 0.25em
    &.center
      position: absolute
      top: 50%
      transform: translate(0%,-50%)
      i
        position: absolute
        left: 0.25em
        right: 0.25em
        margin: auto
        font-size: 1.5em
    &.left
      i
        position: absolute
        bottom: 0.25em
        left: 0.25em
        font-size: 1.5em
    &.right
      i
        position: absolute
        bottom: 0.25em
        right: 0.25em
        font-size: 1.5em
    &.horizontal-center
      i
        left: 0.25em
        right: 0.25em
        text-align: center
    i
      position: absolute
      bottom: 0.25em
      right: 0.25em
      font-size: 1.5em
  .widget-label
    @include font-tg-bold()
    font-size: 0.7em
    text-transform: uppercase
    &.roboto
      font-family: roboto !important
    &.serif
      font-family: serif !important
    &.sans-serif
      font-family: sans-serif !important
    &.light
      font-weight: lighter
    &.light-italic
      font-weight: lighter
      font-style: italic
    &.regular
      font-weight: normal
    &.regular-italic
      font-weight: normal
      font-style: italic
    &.bold
      font-weight: bold
    &.bold-italic
      font-weight: bold
      font-style: italic
    &.black
      font-weight: normal
      color: black !important
    &.black-italic
      font-weight: normal
      color: black !important
      font-style: italic
  .widget-description
    @include font-default()
    padding: 4px
    font-size: 0.6em
  .widget-progress
    position: absolute
    bottom: 10px
    left: 10px
    right: 10px
    .progress
      height: 6px
      margin-bottom: 0

  // .widget-bg, .widget-icon, .widget-label, .widget-progress
  //   transition: opacity ease-in-out 0.3s

  .widget-waiting
    opacity: 0
    transition: opacity ease-in-out 0.3s
    pointer-events: none
    position: absolute
    bottom: 5px
    left: 8px
    color: white
    text-shadow: 0 0 2px rgba(black, 0.5)
    line-height: 1em
    span
      font-size: 10px
      text-transform: uppercase

  .widget-ribbon
    pointer-events: none
    position: absolute
    right: -5px
    top: -5px
    overflow: hidden
    width: 75px
    height: 75px
    text-align: right
    opacity: 0
    transition: opacity 0.1s linear
    span
      font-size: 9px
      font-weight: bold
      color: #FFF
      text-transform: uppercase
      text-align: center
      line-height: 20px
      transform: rotate(45deg)
      width: 100px
      display: block
      background: linear-gradient($brand-danger 0%, darken($brand-danger,10%) 100%)
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1)
      position: absolute
      top: 19px
      right: -21px
      z-index: 1
      &::before
        content: ""
        position: absolute
        left: 0px
        top: 100%
        z-index: -1
        border-left: 3px solid $brand-danger
        border-right: 3px solid transparent
        border-bottom: 3px solid transparent
        border-top: 3px solid $brand-danger
      &::after
        content: ""
        position: absolute
        right: 0px
        top: 100%
        z-index: -1
        border-left: 3px solid transparent
        border-right: 3px solid $brand-danger
        border-bottom: 3px solid transparent
        border-top: 3px solid $brand-danger

  &.waiting, &.downloading, &.disabled
    pointer-events: none
    .widget-bg, .widget-icon, .widget-label
      opacity: 0.2

  &.waiting
    .widget-waiting
      opacity: 1

  &.disabled
    .widget-ribbon
      opacity: 1
