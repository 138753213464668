$red: #900
$gray: #666
$darkgray: #444

$brand-danger: #cf1f26

$footer-height: 25px

$content-padding-top: 15px

$brand-calendar-date-color: #10143d
$brand-roadmap-text-color: #546478

$brand-primary: #35b2e1
$brand-secondary: #7ac043

$navbar-height: 45px

@mixin full-gradient($direction, $color-stops...)
  background-image: linear-gradient($direction, $color-stops)
