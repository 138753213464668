.infoPopover 
  margin-left: -11px !important
.mat-option-text
  text-transform: capitalize
    
.my-learnings
  &.wrapper
    font-weight: 400
    padding: 1em 9em
    overflow-y: auto

    @media (max-width: $screen-sm - 1)
      padding: 1em

      table.md-table:not(.md-row-select) td.md-cell:first-child, table.md-table:not(.md-row-select) th.md-column:first-child
        padding: 0 8px

  .md-content
    background: white
    margin-top: 16px
    padding: 16px 16px 0
    border: 1px solid #707070
    flex: 1
  .mat-table
    max-height: 100% !important

  .tenant-select-container
    margin-top: 4em
    flex-direction: row
    display: flex
    justify-content: center

    .tenant-select-label
      color: black
      font-size: 28px
      font-weight: 300
  
    .md-select
      margin: 0
      margin-left: 80px

  .header-table
    margin-top: 4em

    .header
      background-image: linear-gradient(to right,rgb(0, 89, 146), rgb(4, 6, 8))
      border-top-left-radius: 16px
      border-top-right-radius: 16px
      color: white
      text-align: center
      margin-top: 16px
      padding: 12px 0 16px
      font-size: 52px
      display: flex
      justify-content: left
      align-items: center

    .header img
      width: 200px

    .table-container
      display: flex

      .md-content:last-child
        margin-left: 16px

      .md-table-container
        overflow-x: hidden

    .table-title
      display: flex
      justify-content: space-between
      align-items: center
      padding: 0 0 16px

    .table-title > span
      color: rgb(0, 86, 146)
      font-size: 24px
      font-weight: 600

        
    .table-title .md-select
      margin: 0
      text-transform: capitalize


    .section-header
      background-image: linear-gradient(to right,rgb(227, 250, 254), rgb(255, 255, 255))
      color: #005692
      font-size: 20px
      font-weight: 600

    .learning-row-container
      border-top: 2px rgba(0, 0, 0, 0.1) solid

    .learning-row
      height: 52px
      display: flex
      align-items: center
      border: none
      cursor: pointer
      padding: 0 !important

    .learning-row.failed
      color: #ed779c

    .learning-row button
      background: transparent
      border-width: 0
      margin-right: 12px

    .learning-row button .fa
      font-size: 16px
      padding-right: 2px

    .learning-row button .fa-star-o
      opacity: 0.5

    .resource-name
      margin-left: 12px
      flex: 1

    .learning-item-progress
      margin-top: 8px
      width: 80px

    .learning-item-progress .percent
      font-size: 0.7em

    .learning-item-progress md-progress-linear
      height: 12px
      margin-left: -2px

    .learning-item-progress .md-container
      background-color: white

    .learning-item-progress .md-mode-determinate
      height: 9px
      border-radius: 5px
      border: 1px solid #707070

    .learning-item-progress .md-mode-determinate .md-bar
      height: 12px
      background-color: #B2BB21
