@import "~bootstrap-sass/assets/stylesheets/bootstrap"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/theme"



$container-max-width: 100%
.container 
  @include container-fixed

  @media (min-width: $screen-sm-min)
    width: $container-sm
  
  @media (min-width: $screen-md-min)
    width: $container-md
  
  @media (min-width: $screen-lg-min)
    width: 100%

.modal-fullscreen
  height: auto
.modal-content
  border-radius: 6px !important
// fixes / additions
.form-vertical .form-group
  margin-left: 0
  margin-right: 0

.input-group-addon
  &.info
    background-color: $brand-info
    color: white
  &.success
    background-color: $brand-success
    color: white
  &.danger
    background-color: $brand-danger
    color: white

.input-group.with-selectize
  .selectize-control
    .selectize-input
      border-radius: 4px 0 0 4px
  .input-group-btn
    .btn
      margin-top: -6px
      height: 36px

.table > tbody > tr > td.middle
  vertical-align: middle
