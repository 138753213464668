@use "sass:math"

.modal:not(.modal-confirm, .modal-error, .modal-connection, .modal-layout-update, .modal-message, .modal-video)
  .modal-header
    background: darken($brand-primary, 20%)
    color: white
    border-radius: 4px 4px 0 0
  .modal-footer
    background: lighten($darkgray, 20%)
    border-radius: 0 0 4px 4px


.modal-message
  .modal-header
    background: darken($brand-primary, 20%)
    color: white
    border-radius: 4px 4px 0 0

    p
      text-align: center
      padding-top: 10px

    .modal-title
      color: white
      padding-left: 75px
      padding-top: 15px
      padding-bottom: 10px
      border-bottom: 1px solid
      background-image: url(/images/icon.png)
      background-size: math.div(1024, 16) + px  math.div(1024, 16) + px
      height: math.div(1024, 16) + 10 + px
      background-repeat: no-repeat

  .modal-footer
    background: lighten($darkgray, 20%)
    border-radius: 0 0 4px 4px


.modal-layout-update
  .modal-body
    text-align: center
    padding-top: 0px
    .text-danger
      padding-top: 10px

.modal-connection
  p
    text-align: center
  .modal-content
    background-color: #ddd
  .modal-header
    padding-top: 40px
    border-bottom: none
    .modal-title
      text-align: center
      border: none
      color: $darkgray
  .modal-body
    display: none
  .modal-footer
    padding: 0 0 40px 0
    border-top: none
    text-align: center
    .btn
      min-width: 30%

.modal-confirm
  .modal-content
    background-color: #ddd
  .modal-header
    padding-top: 40px
    border-bottom: none
    .modal-title
      text-align: center
      border: none
      color: $darkgray
  .modal-body
    display: none
  .modal-footer
    padding: 0 0 40px 0
    border-top: none
    text-align: center
    .btn
      width: 30%

.modal-error, .modal-layout-update
  .modal-content
    background-color: #ddd
  .modal-header
    padding-top: 40px
    border-bottom: none
    .modal-title
      text-align: center
      border: none
      color: $darkgray
  .modal-body
    border: none
    padding-bottom: 20px
    p
      text-align: center
  .modal-footer
    padding: 0 0 40px 0
    border-top: none
    text-align: center
    .btn
      width: 30%

.modal-video
  width:94%
  .close
    position: absolute
    right: 10px
  .dynamicLink
    position: absolute
    right: 30px
    color: white
    text-shadow: 0 1px 1px white
    background: transparent
    border: none
  .modal-lg
    width: 94%
  .modal-header
    background: darken($brand-primary, 5%)
    border-radius: 5px 5px 0 0
    padding-bottom: 10px
    .modal-title
      color: white
      margin: 0
    .close
      color: white
      text-shadow: 0 1px 1px black
  .modal-body
    background: white
    border: none
    border-radius: 4px
    .description
      margin-top: 1em
      > i.fa
        float: left
        color: $brand-primary
        line-height: 1em
        margin-right: 3px
        transition: transform 0.2s ease-in-out
      .description-header
        overflow: hidden
        cursor: pointer
        margin: 1em 0
        text-transform: uppercase
        font-weight: normal
        color: $brand-primary
        border-bottom: 1px dotted #999
        line-height: 1.3em
      .video-description-hold
        padding: 30px
        background: #eee
        box-shadow: inset 0 0 5px #ccc
        border-radius: 5px
      .video-description
        font-size: 16px
        p
          margin: 0
          line-height: 1.5em
        p + p
          margin: 1.5em 0 0 0

.modal-layout-update .modal-content
  background: none !important
  background-color: none !important
  box-shadow: none !important
  border: none !important
  margin: 180px auto !important

  h3
    color: #fff !important

  p
    color: #fff !important

.modal-document
  width:94%
  .close
    position: absolute
    right: 10px
  .dynamicLink
    position: absolute
    right: 30px
    color: white
    text-shadow: 0 1px 1px white
    background: transparent
    border: none
  .modal-lg
    width: 94%