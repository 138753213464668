.roadmap-task
  // +clearfix
  clear: left
  cursor: pointer
  padding: 0 0 5px 0
  .roadmap-task-icon
    width: 2em
    height: 2em
    background-repeat: no-repeat
    background-size: contain
    background-position: center
    float: left
    margin: 0 5px 5px 0
  .roadmap-task-content
    overflow: hidden
    .roadmap-task-name
      font-weight: bold
    .roadmap-task-description,
    .roadmap-task-duration
      font-style: italic
      font-size: 0.9em

.roadmap-alternate
  .iscroll-scroller
    .section
      .visited
        background: #5a284b !important

      .fa-check
        color: #fff
        font-size: 22px
        padding: 11px

.roadmap-self-complete-modal
  width: 50%

  .mat-dialog-container
    .mat-dialog-content

      .dialog-message
        margin-bottom: 10px

      textarea
        &.self-complete-reason
          padding: 10px
          resize: none
          height: 150px
          width: 100%

    .mat-dialog-actions

      .spacer
        flex: 1 1 auto

      button
        color: white

        &.btn-confirm
          background-color: #2C5790

        &.btn-cancel
          background-color: #969696

        &.btn-reset
          background-color: #E93322

        &.mat-button-disabled
          background-color: #D3D3D3