@import 'app/vars'
@import "app/fonts"
@import 'app/mixins'
@import 'vendors/font-awesome'
@import 'vendors/bootstrap'
@import 'vendors/google-fonts'
@import 'vendors/delphire-icons'
@import 'app/base'
@import 'app/roadmap-task'
@import 'app/widget'
@import 'app/modals'
@import 'app/library'
@import 'app/quiz'
@import 'app/top-navigation'
@import 'app/footer'
@import 'app/my-learnings'
@import 'app/notifications'
@import 'app/home-page'
@import 'app/favorites-dialog'
@import 'app/disclaimer'
@import '@angular/cdk/overlay-prebuilt.css'

@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"

$font-stack: "Helvetica Neue", "Helvetica-Neue", "HelveticaNeue", Helvetica, Arial, sans-serif

iframe#launcher
  width: 10% !important
  margin: 0px 40px 0 0 !important
  padding: 0 !important
  opacity: 0 !important
  display: inline-block !important
  text-align: left !important

body
  padding: 0px !important
  overflow-y: hidden
  background-repeat: no-repeat
  background-position: center center
  background-size: cover
p
  margin: 0 0 10px
.linkSnackBar
  .mat-simple-snackbar-action button
    color: #a4a4a4
.container
  display: flex
  flex-direction: row
  max-width: 100% !important
  padding:0 !important
  width:100%
.carousel-control-prev, .carousel-control-next
  width:5% !important

.carousel-indicators
    z-index: 1
    .active
        width:30px !important
        height:3px !important

// app-space, app-layout, app-layout-detail
.widget
  border: none !important
.layout-detail-view
    position: absolute
    top:45px
    right:0
    left:0
    bottom:55px
    overflow: auto
    height: calc(100vh - 100px) !important
    padding: 0 2px !important

.space-view, .layout-view, .home
    position: absolute
    top:45px
    right:0
    left:0
    bottom:0
    overflow: auto
    padding-bottom: 100px
    .home-view
      padding: 0
      height: 100vh
      padding-bottom: 100px
      h1
        font-size: 5.2em

    h2
        text-align: center
        margin: 10px
        padding: 5px
        font-size: 24px
        font-family: Roboto, sans-serif
    mat-grid-list
        margin:0 20px 0 0


    mat-grid-tile
        cursor: pointer
        margin: 10px
        box-shadow: 0px 25px 50px rgba(0,0,0,0.1)
        border: 1px solid #70C6DD
        border-radius: 10px
        background: transparent linear-gradient(147deg, #FFFFFF 0%, #E6F9FF 100%) 0% 0% no-repeat padding-box
.roadmap, .library, .quiz, .course-builder, .my-learnings, .notifications, .home-view
    position: absolute
    left:0
    right:0
    top:0
    bottom:55px
    height: fit-content
    color: black
    font-size: 14px
    overflow: auto
    padding-bottom: 150px
    text-align: left
    z-index: 2

    h1
      font-size: 2em
      font-weight: normal
      margin: 0

    h2
      font-size: 1.5em
      margin: 0

    h3
      font-size: 1.25em
      margin: 0
      padding: 0 0 10px 0

    h4
      font-size: 1em
      margin: 0.5em 0
      margin: 0
      padding-bottom: 7px

//! ------------------------------------------------
//! ROADMAP
//! ------------------------------------------------

.roadmap *,
.library *
  // font: normal 16px/21px Roboto, $font-stack
  color: #2E2E2E

.roadmap,
.library
  background: #fff
  padding-bottom: 0 !important
  // background: center top repeat-y url(/images/background-repeat.png)
  background-image: url(/images/MODAL-background-hexagon.png)
  background-size: contain
  height: 100%
  display: flex
  flex-flow: column
  font: 200 16px/21px Roboto, $font-stack

  roadmap-custom
    flex-grow: 1
    display: flex
    flex-direction: column

// ------------------------------------------------

.roadmap-wrapper,
.library-wrapper
  overflow: hidden
  max-width: 1130px
  margin: 0 auto
  padding: 0 10px
  width: 100%
  height: 100%
  position: relative

.roadmap-wrapper-gutters
  padding-left: 16px
  padding-right: 16px

// ------------------------------------------------

.roadmap-tasks-empty
  padding: 0.5em 1.0em

// ------------------------------------------------

.roadmap-header,
.library-header
  padding: 30px 0
  background: #fff
  flex: 0 1 auto

  h1
    font: normal normal medium 32px/43px Roboto, $font-stack
    letter-spacing: 0px
    color: #2E2E2E
    border-bottom: none !important
    margin: 0 !important
    padding-left: 16px
    padding-right: 175px

  p
    padding: 5px 175px 0 16px
    margin: 0

// ------------------------------------------------

.roadmap-nav

  .roadmap-navigate-text
    font-size: 14px
    font-weight: normal
    color: #919191
    padding: 0 0 2px 5px

  .roadmap-levels
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(49%, 1fr))
    gap: 0px 10px

  .mat-select
    background: white
    padding: 10px 10px 3px 10px
    font-size: 20px
    border-radius: 5px
    min-width: 250px

  .roadmap-objectives
    padding: 0 0 0 20px
    border-left: 3px solid #707070
    margin: 0 0 0 20px
    color: #fff
    font-weight: 200 !important

    h4
      color: #fff
      font-size: 16px !important
      padding-bottom: 7px !important
      margin-bottom: 0 !important

    p
      color: #fff
      font-size: 14px !important
      padding-bottom: 7px !important
      margin-bottom: 0 !important

.roadmap roadmap-custom mat-select
  margin-top: 0 !important
  margin-bottom: 30px !important

.roadmap roadmap-custom mat-select .mat-select-value
  padding: 10px 0 15px 10px !important

  .mat-select-value-text
    span
      font-size: 20px !important
      font-weight: bold

// ------------------------------------------------

.roadmap-nav-level-1
  // padding: 30px 0
  padding: 30px 0
  background: #424B58

  md-input-container
    margin: 0

  md-select
    display: inline-block
    min-width: 200px
    margin: 0
    font-size: 20px
    font-weight: bold

    &.md-default-theme .md-select-value.md-select-placeholder, .md-select-value.md-select-placeholder
      color: #000 !important

// ------------------------------------------------

.roadmap-objectives-level-1
  padding: 0

// ------------------------------------------------

.roadmap-nav-level-2
  padding: 20px 15px

  md-select
    display: inline-block
    min-width: 200px
    margin: 0
    font-size: 16px
    font-weight: bold

    &.md-default-theme .md-select-value.md-select-placeholder, .md-select-value.md-select-placeholder
      color: #000 !important

  md-radio-button
    display: inline-block
    min-width: 200px

    &.md-default-theme.md-checked .md-off, &.md-checked .md-off
      border-color: rgba(10, 86, 150, 0.87)

    &.md-default-theme .md-on, .md-on
      background-color: rgba(10, 86, 150, 1)

md-radio-group
  &.md-default-theme .md-checked .md-ink-ripple, .md-checked .md-ink-ripple
    color: rgba(10, 86, 150, 0.26)

.roadmap-nav-level-2 md-radio-button .md-label
  position: relative
  top: -3px

// ------------------------------------------------

.roadmap-objectives-level-2
  // padding: 0 15px 10px 15px
  padding: 20px 0 10px 0

.roadmap-border-bottom
  border-bottom: 1px solid #7E8083

// ------------------------------------------------

.roadmap-controls
  position: relative
  padding: 20px 0 0 0
  display: flex
  justify-content: space-between
  align-items: center
  /* flex-flow: wrap; */
  flex-wrap: wrap
  align-content: center

  &.roadmap-controls-bottom
    .roadmap-filter-controls
      visibility: hidden
      pointer-events: none
      width: 1px
      height: 1px

  &.roadmap-controls-custom
    padding-top: 10px
    justify-content: flex-start

    .roadmap-filter-controls
      position: static
      margin-left: 16px

  .roadmap-filter-controls
    // position: absolute
    // top: 3px
    // left: 16px
    margin-left: 16px

    .mat-form-field-infix
      width: 210px !important

    .mat-form-field-label
      left: 25px
      padding-top: 2px

    .mat-input-element
      color: #424B58 !important
      font-size: 16px !important
      padding-left: 25px
      position: relative
      top: 2px

    mat-label
      color: #424B58 !important
      font-size: 16px !important

    .mat-form-field-underline
      background-color: #fff !important

    .roadmap-filter-icon
      position: absolute
      top: 6px
      left: 0

      svg
        stroke: #59AAE9
        width: 22px
        height: 22px

        path
          stroke-width: 2px

  .roadmap-pagination-controls
    flex: 0 1 auto
    background: #fff
    border-radius: 10px

    *
      font-size: 12px

    .roadmap-pagination-controls-wrapper
      display: flex
      align-items: center
      justify-content: flex-end

      .mat-form-field-underline
        display: none !important

      .mat-form-field-infix
        width: fit-content !important
        background: #F1F2F5 !important
        border-radius: 5px !important
        border-top: 1px !important
        position: relative
        top: 7px
        padding-right: 7px
        margin-left: 7px

      .mat-form-field
         width: auto !important

      .mat-select
        width: auto !important

      .mat-select-value
         max-width: max-content !important
         min-width: 100%

      .roadmap-pagination-spacer
        flex: 1 1 auto

      .roadmap-pagination-select
        position: relative
        padding-right: 2px

        #roadmap-pagination-select
          position: relative
          top: 2px
          right: -11px
          // width: 100px
          text-align: right

          .mat-select-arrow-wrapper
            opacity: 0

        ng-icon
          position: relative
          right: 0
          pointer-events: none

          svg
            stroke: #59AAE9

            path
              stroke-width: 1.5px

      .roadmap-pagination-showing
        color: #666D7A !important
        padding: 0 15px

        span
          color: #666D7A !important
          display: inline-block
          padding: 0 3px

      .roadmap-pagination-main

        button

          svg
            stroke: #59AAE9

            path
              stroke-width: 1.5px

          &:disabled
            svg
              stroke: #666D7A

              path
                stroke-width: 1.5px

// ------------------------------------------------

//! ---------------------------------------------------------

.grid-view
  .roadmap-content-box,
  .library-content-box
    .roadmap-tasks
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr))

      app-task
        .roadmap-item-type-header
          display: block !important
          font-size: 14px

        .roadmap-item
          height: 100%
          min-height: 200px
          padding-bottom: 48px

          .roadmap-item-details
            position: absolute
            bottom: 10px
            gap: 3px 6px

            .roadmap-item-type
              span
                display: none

// ------------------------------------------------

.roadmap-content-box,
.library-content-box
  border: none
  border-radius: 0
  overflow: visible
  // background: center top url(/images/background.png)
  // background-size: cover
  padding-bottom: 50px
  // min-height: 100%
  flex: 1 1 auto

  .roadmap-tasks
    padding-top: 20px
    display: grid
    grid-template-columns: 1fr
    // grid-template-columns: repeat(auto-fit, minmax(275px, 1fr))
    justify-items: start
    align-items: start
    gap: 8px

    &.roadmap-tasks-custom
      padding-top: 10px

    app-task
      display: contents !important

      .roadmap-item-type-header
        display: none !important

      .roadmap-item
        text-align: left
        position: relative
        border: 1px solid #EDEDED
        border-radius: 10px
        background: #fff
        width: 100%

        &:hover
          background: #F9F9F9
          cursor: pointer
          h4
            text-decoration: underline

        h4
          font-size: 20px
          font-weight: bold
          margin: 0
          padding: 0 56px 16px 0

        .roadmap-item-description
          font-size: 16px
          line-height: 21px
          padding: 0 0 16px 0
          margin: 0
          color: #666

          p
            margin: 0px
            padding: 0px
            margin: 0.5em 0

        .roadmap-item-details
          display: flex
          flex-direction: row
          flex-wrap: wrap
          justify-content: flex-start
          align-items: center
          align-content: stretch
          gap: 3px 24px
          font-size: 14px
          padding-top: 8px

          .roadmap-item-item
            height: 36px
            align-content: center
            font-size: 14px

            *
              font-size: 14px

            &.roadmap-item-type
              display: flex
              justify-content: flex-start
              align-items: center
              padding: 6px 0

              .icon
                margin-right: 8px !important

            // &.roadmap-item-duration

            &.roadmap-item-library-items
              border-radius: 6px

              .roadmap-chip
                background: #C1C1C1 !important
                padding-left: 12px !important

              .roadmap-library-items-icon
                margin-right: 5px

                svg
                  width: 22px
                  height: 20px

                  path
                    stroke-width: 1.5px

            .roadmap-chip
              background: #fff
              display: inline-block
              padding: 6px 15px
              border-radius: 6px
              border: 2px solid rgba(255, 255, 255, 0)

              &.roadmap-chip-has-progress
                background: #FFEEB4

              &.roadmap-chip-is-completed
                background: #D3F0D4

              &.roadmap-chip-self-complete
                background: #F8F8F8
                border: 2px solid #424B58
                padding: 4px 15px

                &.completed
                  border: 2px solid rgba(255, 255, 255, 0)
                  background: #D3F0D4

                  ng-icon
                    position: relative
                    top: -2px

        &.delphire-task-completed
          background: #E1E3E7

          *
            color: #7C7D7F

          .roadmap-chip-self-complete
            color: #2E2E2E !important

            &.completed
              svg, svg path
                color: #2E2E2E !important

          .roadmap-item-progress
            .roadmap-chip-is-completed
              color: #2E2E2E !important

.library-toggle
  position: absolute
  top: 0
  right: 10px
  display: inline-block

  label
    padding: 0
    margin: 0
    width: 164px
    background: #E1E3E7
    border: none
    height: 34px
    position: relative
    display: inline-block
    border-radius: 20px
    transition: 0.4s

    &:after
      content: ""
      position: absolute
      border-radius: 20px
      z-index: 2
      background: #fff
      top: 2px
      left: 3px
      width: 80px
      height: 30px
      transition: 0.4s

  input
    position: absolute
    left: 0
    top: 0
    padding: 0
    margin: 0
    width: 100%
    height: 100%
    z-index: 5
    opacity: 0
    cursor: pointer

    &:checked + label:after
      left: 82px

  .icon-gridview
    position: absolute
    top: 5px
    left: 14px
    z-index: 1000
    width: 22px
    height: 22px
    pointer-events: none

    svg
      stroke: #59AAE9
      stroke-width: 2

  .icon-listview
    position: absolute
    top: 5px
    left: 92px
    z-index: 1000
    width: 22px
    height: 22px
    pointer-events: none

    svg
      stroke: #59AAE9
      stroke-width: 2

  .label-grid
    position: absolute
    top: 7px
    left: 40px
    z-index: 10
    font-size: 12px
    pointer-events: none

  .label-list
    position: absolute
    top: 7px
    left: 122px
    z-index: 10
    font-size: 12px
    pointer-events: none


//! ---------------------------------------------------------

.md-padding
  padding: 16px



.roadmap-item-capitalize
  text-transform: capitalize


// ---------------------------------
// ---------------------------------

.roadmap-item-deeplink
  position: absolute
  top: 17px
  right: 44px

  .roadmap-item-deeplink-click
    display: inline-block
    width: 20px
    height: 20px

  svg
    stroke: #C1C1C1

    path
      stroke-width: 2

  &:hover
    svg
      stroke: #2E2E2E
      opacity: 0.5
      // fill: #FFDC64

// ---------------------------------

.roadmap-item-favorite
  position: absolute
  top: 17px
  right: 17px

  svg
    stroke: #C1C1C1

    path
      stroke-width: 1

  &:hover
    svg
      fill: #FFDC64

// ---------------

.roadmap-is-favorite
  svg
    fill: #FFDC64

.roadmap-not-favorite
  svg
    fill: #fff

// ---------------------------------
// ---------------------------------

.hand
  cursor: pointer

.roadmap-item-actions
  text-align: center
  padding-left: 16px
  padding: 8px !important

.roadmap-btn
  // height: 25px
  // max-height: 25px
  // min-height: 25px
  // min-width: 50px
  // font-size: 0.6em
  // line-height: 25px !important
  // border-radius: 5px
  // text-align: center
  text-transform: uppercase
  text-align: center
  color: white
  font-size: 14px
  font-weight: normal

  &.roadmap-btn-loading
    color: white
    background-color: #CCCCCC

    &:hover, &.md-focused
      color: white
      background-color: #CCCCCC

  &.roadmap-btn-start
    color: white
    background-color: #195695
    margin-bottom: 10px

    &:hover, &.md-focused
      background-color: #04407d !important

  &.roadmap-btn-resume
    color: white
    background-color: #F3981E
    margin-bottom: 10px

    &:hover, &.md-focused
      background-color: #d37700 !important

  &.roadmap-btn-complete
    color: white
    background-color: #70C6DD
    margin-bottom: 10px

    &:hover, &.md-focused
      background-color: #309cb9 !important

  &.roadmap-btn-self-complete
    color: #424B58
    background-color: #fff

    &.completed
      background-color: #D3F0D4

      &:hover, &.md-focused
        background-color: #9ca320 !important

    &:hover, &.md-focused
      background-color: #F8F8F8 !important

  &.roadmap-btn-download
    color: white
    background-color: #AEAEAE
    margin-bottom: 10px

    &:hover, &.md-focused
      background-color: #7e7373 !important

  &.roadmap-btn-favorite
    height: 25px
    max-height: 25px
    min-height: 25px
    min-width: 50px
    margin: 0
    font-size: 0.6em
    line-height: 25px
    border-radius: 5px
    text-align: center
    color: white
    background-color: #70C6DD

    &:hover, &.md-focused
      background-color: #309cb9 !important

md-content.roadmap-item.text-only
  padding-top: 30px
  padding-bottom: 23px

  .roadmap-item-details, .roadmap-item-progress, .roadmap-item-actions
    display: none

md-progress-circular.btn-loader
  display: inline-block
  margin: 5px 5px 0 0

.loader-span
  vertical-align: super

md-progress-circular
  &.md-default-theme path, path
    stroke: white

.course-builder
  background: transparent
  color: black
  font-size: 14px
  overflow: auto
  padding-top: 30px

  h1
    font-size: 1.75em

  h2
    font-size: 1.5em

  h3
    font-size: 1.25em

  h4
    font-size: 1em
    margin: 0.5em 0

.course-builder-wrapper
  overflow: hidden
  max-width: 750px
  margin: 0 auto

.course-builder-body
  font-size: 1em
  line-height: 1.2em
  margin-bottom: 2em

.course-builder-item
  background: transparent linear-gradient(98deg, #FFFFFF 0%, #EBF5FF 100%) 0% 0% no-repeat padding-box
  border: 1px solid #7E8083
  border-radius: 10px
  margin-bottom: 1em
  position: relative
  overflow: auto

  &:hover
    background: transparent linear-gradient(98deg, #FFFFFF 0%, #FFF2E0 100%) 0% 0% no-repeat padding-box

  &.text-only
    background: none
    border: none
    border-radius: 0
    margin-top: 1em

    .course-builder-item-description
      font-size: 1.1em

.course-builder-item-capitalize
  text-transform: capitalize

.course-builder-item-description
  font-size: 0.9em
  line-height: 1.2em
  margin-bottom: 0.5em
  font-size: 0.9em

.course-builder-item-details
  font-size: 0.8em

  .course-builder-item-type span
    vertical-align: top

.course-builder-item-progress
  margin-top: 1em

  .percent
    font-size: 0.7em
    margin: 5px 0

  md-progress-linear
    height: 12px

  .md-container
    background-color: white

  .md-mode-determinate
    height: 12px
    border-radius: 3px
    border: 1px solid #707070

    .md-bar
      height: 12px
      background-color: #B2BB21

.course-builder-item-actions
  width: 20%
  text-align: center

.course-builder-btn
  height: 35px
  max-height: 35px
  min-height: 35px
  min-width: 60px
  font-size: 0.7em
  line-height: 35px
  border-radius: 5px
  text-align: center

  &.course-btn-favorite
    height: 25px
    max-height: 25px
    min-height: 25px
    min-width: 50px
    margin: 0
    font-size: 0.6em
    line-height: 25px
    border-radius: 5px
    text-align: center
    margin-bottom: 0.5em
    color: white
    background-color: #70C6DD

    &:hover, &.md-focused
      background-color: #309cb9 !important

  &.course-btn-loading
    color: white
    background-color: #CCCCCC

    &:hover, &.md-focused
      color: white
      background-color: #CCCCCC

  &.course-btn-start
    color: white
    background-color: #195695

    &:hover, &.md-focused
      background-color: #04407d !important

  &.course-btn-resume
    color: white
    background-color: #F3981E

    &:hover, &.md-focused
      background-color: #d37700 !important

  &.course-btn-complete
    color: white
    background-color: #70C6DD

    &:hover, &.md-focused
      background-color: #309cb9 !important

  &.course-btn-download
    color: white
    background-color: #AEAEAE

    &:hover, &.md-focused
      background-color: #7e7373 !important


@media (max-width: 767px)
  .modal.fade.ng-isolate-scope.modal-video.in .modal-dialog
    width: 96% !important

  .course-builder
    padding: 1em 1em 7em 1em

  .course-builder-btn, .course-item-progress
    margin: 1em 0

  .action-items
    padding: 0 0 16px 16px

  .course-builder-btn
    height: 45px
    max-height: 45px
    min-height: 45px
    min-width: 150px
    font-size: 1em
    line-height: 45px

  .course-builder-item
    padding: 0

    &.text-only
      margin: 0

  .course-builder-item-info
    padding: 1em

  .course-builder-item-actions
    width: 100%
    padding: 8px

    // &.start
    //   background-color: #EBFBFF

    // &.resume
    //   background-color: #FFF2E0

    // &.complete
    //   background-color: #E8F3FF

// ---------------------------------

app-deep-link
  width: 100%

  .text
    > div
      padding-bottom: 10px

      &.error
        color: red
        font-weight: bold

    button.popup
      cursor: pointer
      margin: 10px 10px
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1)
      border: 1px solid #70C6DD
      border-radius: 10px
      background: transparent linear-gradient(147deg, #FFFFFF 0%, #E6F9FF 100%) 0% 0% no-repeat padding-box

      text-align: center
      font-family: "trade-gothic-next-condensed", sans-serif
      font-weight: 700
      font-style: normal
      color: #195695
      padding: 1em
      line-height: 1em
// ------------------------------------------------------------------------

.mat-simple-snackbar
  button
    color: #fff !important

// ------------------------------------------------------------------------
