$brand-color-primary: #b83947    // button, headline
$brand-color-secondary: #3669ae  // header
$brand-color-default: #58687c    // secondary buttons, answer frames
$brand-color-highlight: #58687c  // selected state
$white-text: #fff  							 // use this to replace all white text colors

.modal-quiz
	.modal-dialog
		margin: 80px auto !important

	.quiz-brand-header
		background: $brand-color-secondary !important
		color: $white-text  !important

	.quiz-question
		h1, h2, h3, h4
			color: $brand-color-primary !important
			border-bottom: 0px !important


	.quiz-brand-btn-primary
		background-color: $brand-color-primary !important
		color: $white-text !important
		background-image: none
		width: 127px

	.quiz-brand-btn-default
		background-color: $brand-color-default !important
		border: 0px
		color: $white-text !important
		background-image: none

	.modal-body
		background: url(/images/quizbackground.png) no-repeat center

	.quiz-incorrect
		background: url(/images/incorrect.png) no-repeat center
		width: 134px
		height: 134px
		margin: 0 auto
		margin-top: 15px

	.quiz-incorrect-sm
		background: url(/images/incorrect.png) no-repeat center
		width: 90px
		height: 90px
		margin: 0 auto
		background-size: 90px 90px

	.quiz-correct
		background: url(/images/correct.png) no-repeat center
		width: 134px
		height: 134px
		margin: 0 auto
		margin-top: 15px

	.quiz-correct-sm
		background: url(/images/correct.png) no-repeat center
		width: 90px
		height: 90px
		margin: 0 auto
		background-size: 90px 90px

	.quiz-description
		h1, h2, h3, h4
			color: $brand-color-primary !important
			border-bottom: 0px !important

	.answer
		border: 2px solid $brand-color-default !important

	.answer-indicator
		background-color: $brand-color-default !important

	.answer.selected
		border: 2px solid $brand-color-highlight !important
		background-color: $brand-color-highlight !important
		color: $white-text

		.answer-indicator
			color: $brand-color-highlight !important
			background-color: $white-text !important