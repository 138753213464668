// font definitions
@font-face {
  font-family: "montserratblack";
  src: url("../fonts/montserrat-black-webfont.eot");
  src: url("../fonts/montserrat-black-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/montserrat-black-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratbold";
  src: url("../fonts/montserrat-bold-webfont.eot");
  src: url("../fonts/montserrat-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/montserrat-bold-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratlight";
  src: url("../fonts/montserrat-light-webfont.eot");
  src: url("../fonts/montserrat-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/montserrat-light-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "axisbold";
  src: url("../fonts/axis-webfont.eot");
  src: url("../fonts/axis-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/axis-webfont.woff2") format("woff2"),
    url("../fonts/axis-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// Trade Gothic Next LT Pro Bold
@mixin font-tg-bold() {
  font-family: "trade-gothic-next", sans-serif;
  font-weight: 700;
  font-style: normal;
}

// Trade Gothic Next LT Pro Bold Italic
@mixin font-tg-bold-ital() {
  font-family: "trade-gothic-next", sans-serif;
  font-weight: 700;
  font-style: italic;
}

// Trade Gothic Next LT Pro Heavy
@mixin font-tg-heavy() {
  font-family: "trade-gothic-next", sans-serif;
  font-weight: 800;
  font-style: normal;
}

// Trade Gothic Next LT Pro Heavy Italic
@mixin font-tg-heavy-ital() {
  font-family: "trade-gothic-next", sans-serif;
  font-weight: 800;
  font-style: italic;
}

// Trade Gothic Next LT Pro Italic
@mixin font-tg-ital() {
  font-family: "trade-gothic-next", sans-serif;
  font-weight: 400;
  font-style: italic;
}

// Trade Gothic Next LT Pro Light
@mixin font-tg-light() {
  font-family: "trade-gothic-next", sans-serif;
  font-weight: 300;
  font-style: normal;
}

// Trade Gothic Next LT Pro Light Italic
@mixin font-tg-light-ital() {
  font-family: "trade-gothic-next", sans-serif;
  font-weight: 300;
  font-style: italic;
}

// Trade Gothic Next LT Pro Regular
@mixin font-tg-regular() {
  font-family: "trade-gothic-next", sans-serif;
  font-weight: 400;
  font-style: normal;
}

// Trade Gothic Next LT Pro Bold Compressed
@mixin font-tg-bold-comp() {
  font-family: "trade-gothic-next-compressed", sans-serif;
  font-weight: 700;
  font-style: normal;
}

// Trade Gothic Next LT Pro Compressed
@mixin font-tg-comp() {
  font-family: "trade-gothic-next-compressed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

// Trade Gothic Next LT Pro Heavy Compressed
@mixin font-tg-heavy-comp() {
  font-family: "trade-gothic-next-compressed", sans-serif;
  font-weight: 800;
  font-style: normal;
}

// Trade Gothic Next LT Pro Bold Condensed
@mixin font-tg-bold-cond() {
  font-family: "trade-gothic-next-condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
}

// Trade Gothic Next LT Pro Bold Condensed Italic
@mixin font-tg-bold-cond-ital() {
  font-family: "trade-gothic-next-condensed", sans-serif;
  font-weight: 700;
  font-style: italic;
}

// Trade Gothic Next LT Pro Condensed
@mixin font-tg-cond() {
  font-family: "trade-gothic-next-condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

// Trade Gothic Next LT Pro Condensed Italic
@mixin font-tg-cond-ital() {
  font-family: "trade-gothic-next-condensed", sans-serif;
  font-weight: 400;
  font-style: italic;
}

// Trade Gothic Next LT Pro Heavy Condensed
@mixin font-tg-heavy-cond() {
  font-family: "trade-gothic-next-condensed", sans-serif;
  font-weight: 800;
  font-style: normal;
}

// Trade Gothic Next LT Pro Heavy Condensed Italic
@mixin font-tg-heavy-cond-ital() {
  font-family: "trade-gothic-next-condensed", sans-serif;
  font-weight: 800;
  font-style: italic;
}

// font mixins
@mixin font-default() {
  @include font-tg-light();
}

@mixin font-bold() {
  @include font-tg-bold();
}

@mixin font-black() {
  @include font-tg-heavy();
}

@mixin font-axis() {
  font-family: "axisbold", $font-stack;
  font-weight: normal;
}
