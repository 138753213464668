.quiz
  padding-bottom: 0 
  .quiz-layout
    position: relative
    background-color: #41525F
    height: calc(100vh - 100px)
    overflow: hidden

  .left, .right, .quiz-layout, .quiz-landing, .quiz-complete, .quiz-complete-layout
    position: relative
    overflow: hidden
    height: calc(100vh - 100px)
    color: white
    transition: height 0.5s ease-out

  .left
    background-color: #006CBA

    .icon-quiz
      color: white
      font-size: 7em
      margin-top: 15.5vh
      margin-bottom: 30px

    .quiz-landing h2
      color: white
      font-size: 3.9em
      padding: 0 31px
      line-height: 1.2em

    .quiz-description
      color: white
      margin-top: 3em
      padding: 0 3em
      text-align: center
      
      p
        font-size: 2.14em !important
        line-height: 1.2em

    .quiz-info
      // font-size: 0.9em
      color: black
      font-weight: bold
      padding: 8px
      width: 100%

      .question-count
        text-align: right

    .quiz-question
      height: 80vh
      padding: 0 2.5em

      .question-text, .remediation
        font-size: 2.14em
        font-weight: bold
        line-height: 1.5em
        text-align: center
        max-height: calc(50vh - 100px)
        overflow: auto

      .question-text.question-fade
        opacity: 0.5

  .right .mobile-remediation .answer-result, .left .quiz-question .answer-result
    position: relative
    height: 70px
    width: 100%
    margin: 3em 0

  .right .mobile-remediation .answer-result hr, .left .quiz-question .answer-result hr
    margin: 35px 0 34px 0
    border: 0
    height: 1px
    background: white
    background-image: linear-gradient(to right, #ccc, white, #ccc)
    opacity: 0.3

  .right .mobile-remediation .answer-result .icon, .left .quiz-question .answer-result .icon
    position: absolute
    top: -5px
    left: calc(50% - 35px)
    width: 80px
    height: 80px
    border-radius: 100%
    background-color: white
    display: flex
    align-items: center
    justify-content: center
    border: 10px solid #006CBA

  .right .mobile-remediation .answer-result .icon span, .left .quiz-question .answer-result .icon span
    color: #006CBA
    font-size: 34px

  /* -- RIGHT / BOTTTOM ------------------------------------------------------------------- */

  .right
    background-color: #41525F
    font-weight: normal
    display: flex
    align-items: center

    .close-button
      z-index: 1
      position: absolute
      top: 0
      right: 5px
      width: 30px
      height: 30px
      margin: 1em 0.5em 0 0
      display: flex
      justify-content: center
      align-items: center
      color: #41525F
      font-weight: bold
      border-radius: 100%
      background-color: white

    .quiz-landing
      padding: 0 6em
      margin-top: 250px

      p
        text-align: center
        margin-bottom: 3em
        font-weight: normal
        font-size: 1.7em
        line-height: 1.5em

      .start-button
        border-radius: 9999px
        background-color: #FED530
        color: black
        margin-top: 4em
        font-weight: bold
        width: 38%
        height: 8%
        font-size: 1.5em

    .answers
      margin-top: calc(30px + 1em)
      padding: 0 3em
      width: 100%

      .answer
        position: relative
        width: 100%
        min-height: 10vh
        background-color: white
        color: black
        margin-bottom: 0.75em
        font-weight: normal
        display: flex
        align-items: center
        padding: 1.5em
        transition: background-color 0.3s ease-out
        cursor: pointer

        &.dev
          // background-color: #90d0ff

        &.selected
          background-color: #F0D9CA

          &.answercorrectbdr
            background-color: #CAF0CD

          &.answerwrongbdr
            background-color: #F0CACA

        .indicator
          position: absolute
          bottom: 10px
          right: 10px
          font-size: 1.5em

      .result
        position: absolute
        bottom: 20px
        left: 20px
        font-weight: bold

        .quiz-answer-result
          text-transform: uppercase
          margin-top: 2px
          font-size: 1.5em !important

    .mobile-remediation .result.correct, .answers .result.correct
      color: #CAF0CD

    .mobile-remediation .result.incorrect, .answers .result.incorrect
      color: #F0CACA

    .mobile-remediation .result span, .answers .result span
      margin-right: 0.25em

    .submit-continue
      position: fixed
      bottom: 55px
      right: 0
      width: 50%
      height: 93px
      color: black
      background-color: #FED530
      font-weight: bold
      text-transform: uppercase
      display: flex
      align-items: center
      justify-content: center
      transition: bottom 0.3s ease-out, width 0.3s ease-out
      cursor: pointer
      font-size: 1.5em !important

      &.active
        bottom: 55px

      &.inactive
        width: 201px
        
        .continue-arrow
          margin-right: 10px
        .arrow
          font-size: 1.7em

  /* -- QUIZ COMPLETE ------------------------------------------------------------------- */

  .quiz-complete
    position: relative
    width: 100%
    padding: 20px
    background-color: #41525F

  .quiz-layout .close-button
    z-index: 1
    position: absolute
    top: 20px
    right: 20px
    width: 48px
    height: 48px
    margin: 1em 0.5em 0 0
    display: flex
    justify-content: center
    align-items: center
    color: #41525F
    font-weight: bold
    border-radius: 100%
    background-color: #8D979F
    cursor: pointer
    span 
      font-weight: bold
      font-size: 2em !important

  .quiz-complete
    .close-button
      z-index: 1
      position: absolute
      top: 20px
      right: 20px
      width: 48px
      height: 48px
      margin: 1em 0.5em 0 0
      display: flex
      justify-content: center
      align-items: center
      color: #41525F
      font-weight: bold
      border-radius: 100%
      background-color: white
      cursor: pointer

    .quiz-name
      font-size: 1.57em
      color: black
      font-weight: bold

    .quiz-message
      padding: 0 10%
      text-align: center
      margin-top: 15%

      h2
        color: white

      .quiz-result
        width: 100%
        position: relative
        height: 100px
        width: 100%
        margin: 5% 5% 15% 5%

        hr
          margin: 50px 0 49px 0
          border: 0
          height: 1px
          background-image: linear-gradient(to right, #ccc, white, #ccc)
          opacity: 0.3

        .icon
          position: absolute
          top: 0
          left: calc(50% - 90px)
          width: 180px
          height: 180px
          border-radius: 100%
          display: flex
          align-items: center
          justify-content: center

        hr.pass, .icon.pass
          background: #5DCC6E

        hr.fail
          background: #CC5D5D

        .icon
          &.fail
            background: #CC5D5D

          span
            color: #41525F
            font-size: 100px

    .quiz-buttons
      width: 100%

      .completed-button
        width: 165px
        height: 93px
        color: black
        background-color: #FED530
        font-weight: bold
        text-transform: uppercase
        display: flex
        align-items: center
        justify-content: center
        margin: 0 0.5em
        cursor: pointer
        font-size: 1.5em !important

/* -- MOBILE OVERRIDES ----------------------------------------------------------------------- */

@media only screen and (max-width: 599px)

  // .quiz .quiz-layout .left .quiz-landing h2
  //   color: red

  .quiz
    .right, .quiz-layout, .quiz-landing
      height: unset
      min-height: calc(50vh - 50px)

    .quiz-layout .close-button
      position: fixed
      top: 45px
      right: 0px
      z-index: 1

    .quiz-complete
      .close-button
        position: fixed
        top: 45px
        right: 0px
        z-index: 1

      height: calc(100vh - 100px)

    .left
      height: unset
      min-height: calc(50vh - 50px)

      .quiz-question
        height: unset
        min-height: calc(50vh - 50px)
        padding-bottom: 20px

        .question-text.question-fade
          opacity: 1

      .icon-quiz
        margin-top: 5vh

      .quiz-description
        font-size: 1em
        margin-top: 0.5em

      .quiz-question
        .question-text, .remediation
          max-height: unset
          height: unset

        .answer-result
          margin: 0

    .right
      .answers
        padding-bottom: 30px

        &.answer-selected
          padding-bottom: 90px

      .quiz-landing .start-button
        font-size: 1.5em
        font-weight: bold
        margin-top: 0.5em
        width: 70vw
        height: 8vh

      .submit-continue
        &.inactive
          width: 100%
          font-size: 1.5em

        width: 100%
        font-size: 1.5em

      .mobile-remediation
        background-color: #006CBA
        margin-bottom: 60px
        padding: 1.5em 1.5em 50px 1.5em

        .answer-result
          position: relative
          height: 70px
          width: 100%
          margin: 1.5em 0

          hr
            margin: 35px 0 34px 0
            border: 0
            height: 1px
            background: white
            background-image: linear-gradient(to right, #ccc, white, #ccc)
            opacity: 0.3

          .icon
            position: absolute
            top: 0
            left: calc(50% - 35px)
            width: 70px
            height: 70px
            border-radius: 100%
            background-color: white

            /* border: solid 10px #006CBA;
            display: flex
            align-items: center
            justify-content: center

        .remediation
          font-size: 1.2em
          text-align: center

        .result .quiz-answer-result
          font-size: 1.5em
          font-weight: bold
          

    .quiz-complete
      padding: 20px

      .quiz-message .quiz-result
        margin: 20% 0

      .quiz-buttons .completed-button
        margin-top: 2em
        font-size: 1.2em