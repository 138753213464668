[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak
  display: none !important

html
  height: 100%

body
  @include font-default()
  // padding-top: $navbar-height
  font-size: 16px
  min-height: 100%
  color: $gray
  // background-color: #eee
  > [ui-view]
    position: absolute
    top: $navbar-height
    left: 0
    right: 0
    bottom: 55px
    overflow: hidden
    overflow-y: auto
    > .container, > .container-fluid
      background: #eee
      min-height: 100%
      padding-top: $content-padding-top
      &.container-dashboard
        height: 100%
      > h1:first-child, > h2:first-child
        margin-top: 0
      > .row
        height: 100%
        > div
          height: 100%
          > h1:first-child, > h2:first-child
            margin-top: 0

.browser-mobile-safari.state-dashboard-layout-module > [ui-view]
  top: 0

a, button, input, textarea, select
  outline: 0 !important

.fadeview
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0

td.nowrap
  white-space: nowrap
  .btn-group
    .btn
      display: inline-block
      float: none

h1,h2,h3,h4,h5
  @include font-bold()

h1,h2,h3
  color: black
  padding-bottom: 0.05em
  border-bottom: thin dotted rgba($gray,0.2)


// ========================================++
// ========================================++
// ========================================++


.tenant-selection-header
  margin: 3em 0
  @media (max-width: $screen-sm - 1)
    margin: 1em 0

    h1
      font-size: 1em

.id-dashboard

  padding: 0 5em
  @media (max-width: $screen-sm - 1)
    padding: 0 1em

  &.layout-selection
    padding-top: 60px


  &.grid
    md-grid-list

      md-grid-tile figure
        background: transparent linear-gradient(147deg, #FFFFFF 0%, #E6F9FF 100%) 0% 0% no-repeat padding-box
        box-shadow: 0px 25px 50px rgba(0,0,0,0.1)
        border: 1px solid #70C6DD
        border-radius: 10px
        cursor: pointer

        .name
          padding: 0 1em
          line-height: 1em
          @include font-tg-bold-cond()
          color: #195695
          text-align: center

#delphire-search
  padding-bottom: 1.5em

  .delphire-search-results
    margin-bottom: 2em

  .delphire-search-total
    font-size: 0.8em
    margin-bottom: 2em

  .delphire-search-results-icon
    margin-right: 0.75em
    padding-top: 0.3em
    color: black

  .delphire-search-results-name
    font-weight: bold
    color: #195695
    font-size: 1.4em

    &:hover
      text-decoration: underline

  .delphire-search-results-info
    font-size: 0.7em
    color: black
    padding-bottom: 0.75em

    .bold
      font-weight: bold
      font-style: italic

    .delphire-search-results-type
      margin-right: 2.5em


    .delphire-search-results-layout

      .link
        color: #195695

        &:hover
          text-decoration: underline

  .delphire-search-results-description
    color: black

  .delphire-search-pagination
    @include font-bold()

    .page

      span
        margin-top: 5px
        margin-left: 1em

      #delphire-search-page-select
        margin: 0


  @media (max-width: $screen-sm - 1)
    .delphire-search-contents
      padding: 1em !important

    .delphire-search-results-name
      font-size: 1.1em

    .delphire-search-results-description
      font-size: 1em

md-option
  &.delphire-search-option.md-focused:not([selected])
    background-color: white

  &.delphire-search-option[selected]
    background-color: #7E8083 !important

    .md-text
      @include font-bold()
      color: white
