.cdk-overlay-dark-backdrop
  background: #000 !important
  opacity: .85 !important

.disclaimer-overlay
  max-width: 100% !important
  width: 100%
  height: 100%

  .mat-dialog-container
    background: transparent

    .header
      display: none

    .mat-dialog-content
      max-height: unset
      .dialog-message
        font-size: 1.6em
        color: #fff
        width: 75%
        height: 50%
        margin: 250px auto
        text-align: center
        line-height: 1.2em
        // background-color: #195695 !important
        // border-radius: 5px
        // padding: 15px
        // box-shadow: 1px 1px 10px #000
        text-transform: uppercase

    .dialog-buttons
      position: relative
      top: -225px
      margin: 0px auto
      width: 125px

      button
        background-color: #195695 !important
        // box-shadow: 1px 1px 10px #000