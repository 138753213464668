@import "login"
@import "dashboard"
@import "quizzes"
// @import "roadmap"

body
  background-image: url('/brand/roche/assets/images/splash.png')

.home, .space-view, .layout-view
  background-image: url('/brand/roche/assets/images/background.png') !important
  background-repeat: no-repeat !important
  background-size: cover !important

.modal.fade.ng-isolate-scope.modal-video.in .modal-dialog
  width: 50% !important

.space-view mat-grid-tile, .layout-view mat-grid-tile, .home mat-grid-tile
  border: 1px solid #DBD6D1 !important
  border-radius: 0px !important
  background-color: white !important

#delphire-nav-bar .space-logo
  margin: 0 0 0 -5px !important

// temporarily hide stuff

.temp-search-hide
  display: none !important

.dynamic-link
  display: none !important

.delphire-layout
  .widget
    border: none !important
    border-radius: 0 !important
    .widget-bg
      border: none !important
      border-radius: 0px !important 

// temporarily hide search
.temp-search-hide
  display: none !important

.dynamic-link
  display: none !important

//disclaimer style overrides
.roche-body
  text-transform: none !important
  font-size: .7em !important
  text-align: left !important 
  
  h2
    font-size: 1.5em !important
    color: #fff !important
    margin: 0 !important

  
  p
    line-height: 1.2em !important
    


// --- if iphone media query is needed ---
@media only screen and (min-device-width: 375px) and (max-device-width: 667px)
  .navbar-fixed-top .navbar-search
    right: 3.7em
  .navbar-fixed-top .navbar-logo-tenant
    transform: scale(0.6, 0.6)!important
  .disclaimer
    // font-size: 0.36em !important
  .modal-quiz
    quiz-question
      .quiz-buttons
        button.btn-lg
          font-size: 12px !important
  .modal-quiz
    .quiz-buttons
      bottom: 15% !important
  .modal-quiz
    .quiz-question
      height: 71% !important
.iphone-toolbar-content
  .flex-10
    max-height: 40px
    .navbar-back-to-delphire > li > a
      padding-top: 4px

// color a caret
.md-select-value .md-select-icon:after
  color: #04407D !important

// prevent genentech from making font sizing decisions...
.new-ui.library .aside .bottom .file-description .description 
  h1, h2, h3, h4, h5, h6, li, blockquote
    font-size: 0.8em !important
    font-weight: normal !important

// need to see the link
.roadmap-item-description
  p 
    color: #000 !important
    a 
      color: #195695 !important
      text-decoration: underline !important
    a:active, a:hover
      color: #195695 !important
      text-decoration: underline !important
      
.course-builder-item-description
  p 
    color: #000 !important
    a 
      color: #195695 !important
      text-decoration: underline !important
    a:active, a:hover
      color: #195695 !important
      text-decoration: underline !important

// footer colors and side nav
footer .disclaimer, footer .support
  background-color: #706B69 !important

footer .footer-bottom .f1
  background-color: #544F4F !important
footer .footer-bottom .f2
  background-color: #544F4F !important
footer .footer-bottom .f3
  background-color: #544F4F !important

#delphire-nav-bar .mat-sidenav
  // background: transparent linear-gradient(180deg, #E93725 0%, #F68825 100%) 0% 0% no-repeat padding-box
  background: #706B69 !important


.roadmap-item-duration
  display: block

@media only screen and (min-device-width: 375px) and (max-device-width: 1024px)
  .my-learnings
    .header-table
      .table-container
        -webkit-overflow-scrolling: touch
        // height: 480px

        .learning-item-progress
          .md-bar2
            height: 7px !important
