.notifications
    // top: 45px !important
    position: relative !important
    .mat-list-item
        padding: 1em 0 1em 0 !important
        cursor: pointer
        min-height: 72px !important
    .mat-list-item-text
        flex: 1 1 auto
    .notifications-name
        text-align: center
        padding: 1.0em
        background-color:#195695
        
        h2
            color: white
            border: none
            font-family: Roboto, sans
            font-weight: normal
    h3 
        border: none
        font-family: Roboto, sans
        font-weight: normal
        font-size: medium !important