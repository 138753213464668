@font-face {
  font-family: 'DELPHIRE-201712';
  src:  url('../fonts/DELPHIRE-201712.eot?j1s3n4');
  src:  url('../fonts/DELPHIRE-201712.eot?j1s3n4#iefix') format('embedded-opentype'),
    url('../fonts/DELPHIRE-201712.ttf?j1s3n4') format('truetype'),
    url('../fonts/DELPHIRE-201712.woff?j1s3n4') format('woff'),
    url('../fonts/DELPHIRE-201712.svg?j1s3n4#DELPHIRE-201712') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'DELPHIRE-201712' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.da-2x {
  font-size: 2em;
}


.icon-PDF:before {
  content: "\e953";
}
.icon-quiz_old:before {
  content: "\e954";
}
.icon-speak_old:before {
  content: "\e955";
}
.icon-agenda:before {
  content: "\e900";
}
.icon-analytics:before {
  content: "\e901";
}
.icon-assessment_old:before {
  content: "\e902";
}
.icon-assessment:before {
  content: "\e903";
}
.icon-atom:before {
  content: "\e904";
}
.icon-book:before {
  content: "\e905";
}
.icon-call:before {
  content: "\e906";
}
.icon-cells:before {
  content: "\e907";
}
.icon-certform:before {
  content: "\e908";
}
.icon-certreports:before {
  content: "\e909";
}
.icon-chart:before {
  content: "\e90a";
}
.icon-chat:before {
  content: "\e90b";
}
.icon-check:before {
  content: "\e90c";
}
.icon-clipboard:before {
  content: "\e90d";
}
.icon-compass:before {
  content: "\e90e";
}
.icon-converge:before {
  content: "\e90f";
}
.icon-countdown:before {
  content: "\e910";
}
.icon-dataviz_old:before {
  content: "\e911";
}
.icon-dataviz:before {
  content: "\e912";
}
.icon-delphire:before {
  content: "\e913";
}
.icon-departure:before {
  content: "\e914";
}
.icon-DMtoolkit:before {
  content: "\e915";
}
.icon-dna:before {
  content: "\e916";
}
.icon-documents:before {
  content: "\e917";
}
.icon-engagepro:before {
  content: "\e918";
}
.icon-enter:before {
  content: "\e919";
}
.icon-eventinfo:before {
  content: "\e91a";
}
.icon-favorites:before {
  content: "\e91b";
}
.icon-flashcards:before {
  content: "\e91c";
}
.icon-folder:before {
  content: "\e91d";
}
.icon-game:before {
  content: "\e91e";
}
.icon-home:before {
  content: "\e91f";
}
.icon-hotel:before {
  content: "\e920";
}
.icon-image:before {
  content: "\e921";
}
.icon-journal:before {
  content: "\e922";
}
.icon-key:before {
  content: "\e923";
}
.icon-launch:before {
  content: "\e924";
}
.icon-leaderboard:before {
  content: "\e925";
}
.icon-leadershiptoolkit:before {
  content: "\e926";
}
.icon-lightbulb:before {
  content: "\e927";
}
.icon-lightning:before {
  content: "\e928";
}
.icon-link:before {
  content: "\e929";
}
.icon-list:before {
  content: "\e92a";
}
.icon-location:before {
  content: "\e92b";
}
.icon-lock:before {
  content: "\e92c";
}
.icon-magnifyingglass:before {
  content: "\e92d";
}
.icon-mail:before {
  content: "\e92e";
}
.icon-map:before {
  content: "\e92f";
}
.icon-module:before {
  content: "\e930";
}
.icon-molecule:before {
  content: "\e931";
}
.icon-network:before {
  content: "\e932";
}
.icon-notes:before {
  content: "\e933";
}
.icon-notification:before {
  content: "\e934";
}
.icon-open:before {
  content: "\e935";
}
.icon-paperclip:before {
  content: "\e936";
}
.icon-pin:before {
  content: "\e937";
}
.icon-podcast:before {
  content: "\e938";
}
.icon-polls:before {
  content: "\e939";
}
.icon-presentation:before {
  content: "\e93a";
}
.icon-puzzple:before {
  content: "\e93b";
}
.icon-QRreader:before {
  content: "\e93c";
}
.icon-questionaire:before {
  content: "\e93d";
}
.icon-quiz:before {
  content: "\e93e";
}
.icon-roadmap:before {
  content: "\e93f";
}
.icon-roster:before {
  content: "\e940";
}
.icon-slidedeck:before {
  content: "\e941";
}
.icon-social:before {
  content: "\e942";
}
.icon-speak:before {
  content: "\e943";
}
.icon-syringe:before {
  content: "\e944";
}
.icon-table:before {
  content: "\e945";
}
.icon-target:before {
  content: "\e946";
}
.icon-touch:before {
  content: "\e947";
}
.icon-triviachallenge:before {
  content: "\e948";
}
.icon-trophy:before {
  content: "\e949";
}
.icon-verbalizer:before {
  content: "\e94a";
}
.icon-video:before {
  content: "\e94b";
}
.icon-videolibrary:before {
  content: "\e94c";
}
.icon-videoplayer:before {
  content: "\e94d";
}
.icon-videoproduction:before {
  content: "\e94e";
}
.icon-weather:before {
  content: "\e94f";
}
.icon-webex:before {
  content: "\e950";
}
.icon-wifi:before {
  content: "\e951";
}
.icon-workshops:before {
  content: "\e952";
}
