footer
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 3
  text-transform: uppercase
  color: white
  background: #195695
  @media (max-width: $screen-sm - 1)
    // margin: 15px -15px 0 -15px
    text-align: center
  .container, .container-fluid
    padding: 0 5px
  span, a
    color: #eee !important
  .pull-right
    @media (max-width: $screen-sm - 1)
      float: none !important
      display: block

  .disclaimer, .support
    height: 50px
    background-color: #195695
    color: #fff
    font-size: 0.6em
    line-height: 50px
    text-align: center
    @include font-tg-bold-cond()

    @media (max-width: $screen-sm - 1)
      .disclaimer-text
        width: 100%
        height: 100%
        padding: 0 2em
        line-height: normal
        white-space: normal


  .support
    background: transparent url('/images/question.svg') 0% 50% no-repeat padding-box
    text-transform: capitalize
    text-align: left
    padding-left: 30px
    cursor: pointer

    @media (max-width: $screen-sm - 1)
      background-size: 50% 50%
      span
        display: none

  .footer-bottom
    height: 5px
    .f1
      background-color: #1B9EC6
      width: 80%
      @media (max-width: $screen-sm - 1)
        display: none
    .f2
      background-color: #70C6DD
      width: 15%
    .f3
      background-color: #F3981E
      // width: 2vw